<template>
  <b-row>
    <b-col sm="12" xl="8">
      <c-card-action title="Filter" :badge="$store.getters['filter/isPurchaseReturnsFiltered'] ? 'Filtered':''" collapsed>
        <c-form ref="filterForm" @submit="filterSubmit">
          <div class="d-flex justify-content-between">
            <form-date :max="purchaseReturnsFilter.date_2" class="w-100 mr-1" v-model="purchaseReturnsFilter.date_1" label="Periode Awal"></form-date>
            <form-date :min="purchaseReturnsFilter.date_1" class="w-100" v-model="purchaseReturnsFilter.date_2" label="Periode Akhir"></form-date>
          </div>
          <form-select-all multiple v-model="purchaseReturnsFilter.contact" label="Pemasok" endpoint="v1/contacts/select2"></form-select-all>
          <b-button class="mr-1" type="submit" variant="primary">Sortir</b-button>
          <b-button @click="resetFilter" variant="outline-warning">Reset</b-button>
        </c-form>
      </c-card-action>
    </b-col>
    <b-col sm="12">
      <c-card title="Daftar">
        <template slot="action">
          <b-button v-if="cP(53)" :to="{name:'purchasereturns-create'}" variant="primary"><feather-icon icon="PlusIcon"></feather-icon> Tambah</b-button>
        </template>
        <pagination-table :sortKey.sync="sortKey" :sortDesc.sync="sortDesc" ref="table" endpoint="v1/purchase_returns/index" :fields="fields" :queryParams="filterParams">
          <template v-slot:date="{item}">
            {{item.date | mindate}}
          </template>
          <template v-slot:grand_total="{item}">
            Rp.{{item.grand_total | formatNumber}}
          </template>
          <template v-slot:id="{item}">
            <b-button-group size="sm">
              <b-button v-if="cP(54)" variant="primary" :to="{name:'purchasereturns-edit',params:{id:item.id}}"><feather-icon icon="EditIcon"></feather-icon></b-button>
              <b-button v-if="cP(53)" variant="danger" @click="confirmDelete(item)"><feather-icon icon="TrashIcon"></feather-icon></b-button>
            </b-button-group>
          </template>
        </pagination-table>
      </c-card>
    </b-col>
  </b-row>
</template>

<script>
import PaginationTable from '@/utils/components/PaginationTable.vue'
import CCard from '@/utils/components/CCard.vue'
import {BButtonGroup,BButton} from 'bootstrap-vue'
import FormDate from '@/utils/components/FormDate.vue'
import CForm from '@/utils/components/CForm.vue'
import CCardAction from '@/utils/components/CCardAction.vue'
import FormSelectAll from '@/utils/components/FormSelectAll.vue'
import { mapFields } from 'vuex-map-fields'
import map from 'lodash/map'
import join from 'lodash/join'

export default {
  components:{
    CCard,
    PaginationTable,
    BButtonGroup,
    BButton,
    FormDate,
    CCardAction,
    FormSelectAll,
    CForm
  },
  data(){
    const fields = [
      {key:'code',label:'No. Nota',sortable:true},
      {key:'date',label:'Tanggal',sortable:true},
      {key:'id_contact',label:'Pemasok',sortable:true},
      {key:'note',label:'Catatan',sortable:true},
      {key:'grand_total',label:'Total',sortable:true},
      {key:'id',label:'',tdClass:'d-flex justify-content-end'},
    ]
    return {
      fields,
      sortKey:'date',
      sortDesc:true
    }
  },
  computed:{
    ...mapFields('filter',['purchaseReturnsFilter']),
    filterParams(){
      const dt = JSON.parse(JSON.stringify(this.purchaseReturnsFilter))
      let fd = {}
      if(dt.date_1) fd.date_1 = dt.date_1
      if(dt.date_2) fd.date_2 = dt.date_2
      if(dt.contact.length>0) fd.id_contact = join(map(dt.contact,n => n.value),',')
      return fd
    },
  },
  methods:{
    confirmDelete(item){
      // this.confirmation(`submitDelete`,`Anda akan menghapus Pemasok <b>${item.name}</b>`,`Apakah Anda Yakin ?`,{id:item.id,name:item.name})
      this.confirmation(`submitDelete`,{text:`Anda akan menghapus Penerimaan <b>${item.code}</b>`,parameter:{id:item.id,code:item.code}})
    },
    filterSubmit(){
      this.$refs.table.refresh()
    },
    resetFilter(){
      const vm = this
      vm.$store.commit('filter/RESET_PURCHASE_RETURNS')
      vm.$nextTick(()=>{
        vm.$refs.table.refresh()
      })
    },
    async submitDelete({id,code}){
      try {
        await this.$http.delete(`v1/purchase_returns/delete/`,{params:{id,code}})
        this.notify(`Data Berhasil Dihapus`,`OK`)
        this.$refs.table.refresh()
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
</script>

<style>

</style>